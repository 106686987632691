import React, { PropsWithChildren, useContext } from "react";
import { FormRenderProps } from "react-final-form";
import RAFTabGroupBtn, {
  tabGroupBtnObject,
} from "../Navigation/RAFTabGroupBtn";
import { RAFAttributesContext } from "../Providers/RAFAttributeRelatedListProvider";
import {
  Guid,
  IsNotNullOrWhiteSpace,
  getTimeDifferenceInMinutes,
  getUTCDateValue,
  isNotEmptyArray,
  isNotNullAndUndefined,
  roundToNearestInterval,
} from "../helpers/utils";
import { RAFUIType } from "../models/Common/RAFDataType";
import RAFDateTimePicker from "./RAFDateTimePicker";
import RAFDurationPicker from "./RAFDurationPicker";
import {
  RAFFieldProps,
  RAFFormContext,
  getFormValue,
  setFormValue,
} from "./RFFUtils";

interface IProps {
  endDateLabel?: string;

  endDateField?: string;
  displayStyle?: "SplitStyle" | "TimePicker" | "DesktopTimePicker" | "Default";

  startDateUItype?: string;
  endDateUItype?: string;

  updateEndDateOnStartDateChange?: boolean;
  roundOff?: boolean;
  allowKeyboardInteraction?: boolean;

  interval?: number;
  startEndDateDurationHrs?: number;
  hideDuration?: boolean;
  disableDatePicker?: boolean;

  defaultEndDateInput?: "DateTimePicker" | "DurationPicker";

  minStartDateValue?: Date;
  maxStartDateValue?: Date;

  minEndDateValue?: Date;
  maxEndDateValue?: Date;

  onChangeStartDateEndDate?: () => void;
  durationHrMinsLabel?: "fullText" | "shortText";
}

function RAFStartDateEndDatePicker<T>({
  field,
  endDateField,

  defaultEndDateInput = "DurationPicker",
  startDateUItype = RAFUIType.DateTime,

  displayStyle = "SplitStyle",

  roundOff = true,
  interval = 5,

  allowKeyboardInteraction = false,
  hideDuration = false,

  updateEndDateOnStartDateChange = true, //if true then it will update end date on start date change
  startEndDateDurationHrs = 1, //if updateEndDateOnStartDateChange is true then it will add this hours to start date and set it to end date

  ...props
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  const intervalValue = isNotNullAndUndefined(interval) ? interval : 5;
  const startEndDateDurationHrsValue = isNotNullAndUndefined(
    startEndDateDurationHrs
  )
    ? startEndDateDurationHrs
    : 1;

  const newGuid = Guid.newGuid();

  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const rafAttributesContext = useContext(RAFAttributesContext);

  const queryAttributes =
    isNotNullAndUndefined(rafAttributesContext) &&
      isNotNullAndUndefined(rafAttributesContext.queryAttributes)
      ? rafAttributesContext.queryAttributes
      : [];

  const endDatesessionAttribute =
    isNotEmptyArray(queryAttributes) && isNotNullAndUndefined(endDateField)
      ? queryAttributes.find(
        (x) =>
          x.AttributeName ===
          (endDateField === "EndDate" ? "end_date" : "end_time")
      )
      : null;

  const endDateUItype = isNotNullAndUndefined(props.endDateUItype)
    ? props.endDateUItype
    : isNotNullAndUndefined(endDatesessionAttribute)
      ? endDatesessionAttribute.UIType
      : RAFUIType.DateTime;
  const endDatesessionAttributeDisplayName = isNotNullAndUndefined(
    endDatesessionAttribute
  )
    ? endDatesessionAttribute.DisplayName
    : null;

  const startDateLabel = isNotNullAndUndefined(props.label)
    ? props.label
    : startDateUItype === RAFUIType.TimeOnly
      ? "Start Time"
      : "Start Date";
  const endDateLabel = isNotNullAndUndefined(props.endDateLabel)
    ? props.endDateLabel
    : endDateUItype === RAFUIType.TimeOnly
      ? "End Time"
      : endDatesessionAttributeDisplayName ?? "End Date";

  const durationFieldName = "Duration";
  const durationFieldAttributeName = "duration";
  const durationsessionAttribute =
    hideDuration !== true && isNotEmptyArray(queryAttributes)
      ? queryAttributes.find(
        (x) => x.AttributeName === durationFieldAttributeName
      )
      : null;

  const isDurationFieldExist = isNotNullAndUndefined(durationsessionAttribute)
    ? true
    : false;

  const outerDivClassName =
    isNotNullAndUndefined(endDatesessionAttribute) &&
      (startDateUItype === RAFUIType.TimeOnly ||
        endDateUItype === RAFUIType.TimeOnly ||
        (startDateUItype === RAFUIType.DateOnly &&
          endDateUItype === RAFUIType.DateOnly))
      ? "col-md-6"
      : "col-md-12";

  const roundOfftimeValue = (date: Date, minutes: number = intervalValue) => {
    if (roundOff) {
      return roundToNearestInterval(date, minutes);
    } else {
      return date;
    }
  };

  const onChangeStartDate = (startDateValue) => {
    if (
      updateEndDateOnStartDateChange &&
      isNotNullAndUndefined(startEndDateDurationHrsValue)
    ) {
      if (isNotNullAndUndefined(startDateValue)) {
        // const endDateFormValue = getFormValue(rafFormContextValue, endDateField);
        // if (isNotNullAndUndefined(endDateFormValue)) {
        //   if (isDurationFieldExist) {
        //     const duration = getTimeDifferenceInMinutes(value, endDateFormValue);
        //     setFormValue(rafFormContextValue, durationFieldName, duration);
        //   }
        // } else {

        const selectedStartDate = roundOfftimeValue(startDateValue, intervalValue);

        const endDate = new Date(selectedStartDate);
        let hours = selectedStartDate.getHours() + startEndDateDurationHrsValue;
        endDate.setHours(hours);

        let newEndDate = roundOfftimeValue(endDate, intervalValue);
        setFormValue(rafFormContextValue, endDateField, newEndDate);

        if (isDurationFieldExist) {
          const duration = getTimeDifferenceInMinutes(selectedStartDate, newEndDate);
          setFormValue(rafFormContextValue, durationFieldName, duration);
        }
        //}
      }
      onChangeStartDateEndDate();
    } else if (isDurationFieldExist) {
      if (IsNotNullOrWhiteSpace(startDateValue)) {
        const duration = getFormValue(rafFormContextValue, durationFieldName);
        const startDate = roundOfftimeValue(startDateValue, intervalValue);
        const durationInMinutes = IsNotNullOrWhiteSpace(duration)
          ? parseInt(duration)
          : 0;
        if (durationInMinutes !== 0) {
          const endDate = new Date(startDate.getTime());
          endDate.setMinutes(startDate.getMinutes() + durationInMinutes);
          setFormValue(rafFormContextValue, endDateField, endDate);
        }
      } else {
        setFormValue(rafFormContextValue, durationFieldName, null);
      }
      onChangeStartDateEndDate();
    } else {
      onChangeStartDateEndDate();
    }
  };

  const onChangeEndDate = (value) => {
    if (isDurationFieldExist) {
      const startDateValue = getUTCDateValue(
        getFormValue(rafFormContextValue, field.toString())
      );

      if (
        IsNotNullOrWhiteSpace(value) &&
        IsNotNullOrWhiteSpace(startDateValue)
      ) {
        const duration = getTimeDifferenceInMinutes(startDateValue, value);
        setFormValue(rafFormContextValue, durationFieldName, duration);
      } else {
        setFormValue(rafFormContextValue, durationFieldName, null);
      }
      onChangeStartDateEndDate();
    } else {
      onChangeStartDateEndDate();
    }
  };

  const onChangeDuration = (value) => {
    const startDateValue = getUTCDateValue(
      getFormValue(rafFormContextValue, field.toString())
    );

    if (IsNotNullOrWhiteSpace(startDateValue)) {
      if (isNotNullAndUndefined(value)) {
        const startDate = new Date(startDateValue);
        const durationInMinutes = IsNotNullOrWhiteSpace(value)
          ? parseInt(value)
          : 0;
        const endDate = new Date(startDate.getTime());
        endDate.setMinutes(startDate.getMinutes() + durationInMinutes);
        setFormValue(rafFormContextValue, endDateField, endDate);
      } else {
        setFormValue(rafFormContextValue, endDateField, null);
      }
      onChangeStartDateEndDate();
    } else {
      setFormValue(rafFormContextValue, endDateField, null);
      onChangeStartDateEndDate();
    }
  };

  const onChangeStartDateEndDate = () => {
    if (props.onChangeStartDateEndDate) {
      props.onChangeStartDateEndDate();
    }
  };

  const getStartDateContent = () => {
    return (
      <div className={`${outerDivClassName}`}>
        <RAFDateTimePicker
          field={field}
          label={startDateLabel}
          displayStyle={displayStyle}
          onChanged={onChangeStartDate}
          showLabel={props.showLabel}
          required={props.required}
          disableDatePicker={props.disableDatePicker}
          uiType={startDateUItype ?? RAFUIType.DateTime}
          roundOff={roundOff}
          interval={intervalValue}
          allowKeyboardInteraction={allowKeyboardInteraction}
          minDateValue={props.minStartDateValue}
          maxDateValue={props.maxStartDateValue}
          formGroupClassName="mb-0"
        />
      </div>
    );
  };

  const isStartDateGreaterThanEndDate = (endDateFieldValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue[field.toString()];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "End date should be greater than start date";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const isValidDuration = (durationFielValue, allFormValue) => {
    let hasError = false,
      error = "";
    if (IsNotNullOrWhiteSpace(allFormValue)) {
      const startDateFieldValue = allFormValue[field.toString()];
      const endDateFieldValue = allFormValue[endDateField];
      if (
        isNotNullAndUndefined(startDateFieldValue) &&
        IsNotNullOrWhiteSpace(endDateFieldValue)
      ) {
        const startDate = new Date(startDateFieldValue);
        const endDate = new Date(endDateFieldValue);

        if (
          isNotNullAndUndefined(startDate) &&
          isNotNullAndUndefined(endDate)
        ) {
          if (startDate > endDate) {
            hasError = true;
            error = "Not a valid duration.";
          }
        }
      }
    }

    return hasError ? error : undefined;
  };

  const getEndDateContent = () => {
    if (isNotNullAndUndefined(endDatesessionAttribute)) {
      const minDateValue = roundOfftimeValue(
        getFormValue(rafFormContextValue, field.toString()),
        intervalValue
      );
      return (
        <div
          className={`${outerDivClassName} ${isDurationFieldExist && defaultEndDateInput === "DurationPicker"
            ? "hidden"
            : ""
            }`}
          id={`endDateTimePicker_EndDate${newGuid}`}
        >
          <RAFDateTimePicker
            field={endDateField}
            label={endDateLabel}
            showLabel
            onChanged={onChangeEndDate}
            displayStyle={displayStyle}
            //minDateValue={minDateValue}
            required={
              props.required === true
                ? true
                : endDatesessionAttribute.IsRequired
            }
            disableDatePicker={props.disableDatePicker}
            uiType={endDateUItype ?? RAFUIType.DateTime}
            roundOff={roundOff}
            {...(isDurationFieldExist
              ? {
                labelRightSection: labelRightSection("time"),
              }
              : {})}
            interval={intervalValue}
            allowKeyboardInteraction={allowKeyboardInteraction}
            validators={[isStartDateGreaterThanEndDate]}
            minDateValue={props.minEndDateValue}
            maxDateValue={props.maxEndDateValue}
            formGroupClassName="mb-0"
          />
        </div>
      );
    } else {
      return null;
    }
  };

  const getDurationContent = () => {
    if (isNotNullAndUndefined(durationsessionAttribute)) {
      return (
        <div
          className={`${outerDivClassName} ${defaultEndDateInput === "DateTimePicker" ? "hidden" : ""
            }`}
          id={`durationTimePicker_Duration_${newGuid}`}
        >
          <RAFDurationPicker
            field={"Duration"}
            {...(isNotNullAndUndefined(durationsessionAttribute.DisplayName)
              ? {
                label:
                  endDateUItype === RAFUIType.TimeOnly
                    ? "End"
                    : durationsessionAttribute.DisplayName,
              }
              : {})}
            showLabel
            required={durationsessionAttribute.IsRequired}
            labelRightSection={labelRightSection("duration")}
            onChanged={onChangeDuration}
            interval={intervalValue}
            validators={[isValidDuration]}
            durationHrMinsLabel={props.durationHrMinsLabel}
            formGroupClassName="mb-0"
          />
        </div>
      );
    } else {
      return null;
    }
  };

  //grp start

  const onSelectGroubBtn = (item: tabGroupBtnObject) => {
    if (isNotNullAndUndefined(item)) {
      const endDateTimePicker_EndDate = document.getElementById(
        `endDateTimePicker_EndDate${newGuid}`
      );
      const durationTimePicker_Duration = document.getElementById(
        `durationTimePicker_Duration_${newGuid}`
      );
      if (
        isNotNullAndUndefined(endDateTimePicker_EndDate) &&
        isNotNullAndUndefined(durationTimePicker_Duration)
      ) {
        if (item.id === "time") {
          endDateTimePicker_EndDate?.classList.remove("hidden");
          durationTimePicker_Duration?.classList.add("hidden");
        } else {
          endDateTimePicker_EndDate?.classList.add("hidden");
          durationTimePicker_Duration?.classList.remove("hidden");
        }
      }
    }
  };

  const labelRightSection = (selectedItem) => {
    const groupBtnItems = [
      {
        iconClass: "fas fa-timer",
        id: "time",
        title: "Time",
      },
      {
        iconClass: "fa-solid fa-hourglass-half",
        id: "duration",
        title: "Duration",
      },
    ];

    return (
      <div className="ms-auto">
        <RAFTabGroupBtn
          items={groupBtnItems}
          mode="TextOnly"
          selectedItem={selectedItem}
          onSelect={onSelectGroubBtn}
          uiType="RadioBtn"
          buttonStyle="secondary"
        />
      </div>
    );
  };
  //grp btn end

  return (
    <div className="row gx-2 gy-3">
      {getStartDateContent()}
      {getEndDateContent()}
      {getDurationContent()}
    </div>
  );
}

export default React.memo(RAFStartDateEndDatePicker);
